import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Cta() {
    const scrollToElement = (id) => {
      const container = document.getElementById(id.substring(1));
      container.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={8}  data-aos="fade-left" data-aos-duration="600">
                <h2>Let’s work together for your online success</h2>
            </Col>
            <Col md={4}  data-aos="fade-right" data-aos-duration="600">
                <div className='text-center'>
                    <Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Cta