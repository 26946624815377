import { React } from 'react'
import Hero from './Hero';
import OurBusiness from './OurBusiness';
import Cta from './Cta';
import WhoWeAre from './WhoWeAre';
import GlobalLeaders from './GlobalLeaders';
import HowWorks from './HowWorks';
import GetInTouch from './GetInTouch';

function Home({ sectionRefs }) {
    
  return (
    <>
        <section className='hero' id='home'  ref={sectionRefs[0]}>
          <Hero />
          <div className='video-bg'>
            <video id="background-video" autoPlay loop muted poster="./assets/img/digital-world-banner-background-remixed-from-public-domain-by-nasa-1.jpg">
              <source src="./assets/img/Technology-video.webm" type="video/mp4" />
            </video>
          </div>
          <div className='divider'></div>
        </section>
        <section className='ourbusiness' id='ourbusiness'>
          <OurBusiness/>
        </section>
        <section className='cta'>
          <Cta/>
        </section>
        <section className='WhoWeAre' id='whoweare'>
          <WhoWeAre/>
        </section>
        <section className='globalLeaders'>
          <div className='divider-2'></div>
          <GlobalLeaders/>
          <div className='divider-3'></div>
        </section>
        <section className='HowWorks'>
          <HowWorks/>
        </section>
        <section className='getintouch' id='contact'>
          <GetInTouch/>
        </section>
    </>
  )
}

export default Home