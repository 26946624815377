import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import ListGroup from 'react-bootstrap/ListGroup';
// import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
// import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
// import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
// import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
// import { Link } from "react-router-dom";

// import {  useLocation } from "react-router-dom";

function Footer() {
  
  return (
    <>
      <div>
        <Container>
          <Row>
              <Col md={12}>
                <p className='text-center'  data-aos="fade-in" data-aos-duration="1000">
                  <a href="/" ><img src="./assets/img/HasMedia-1.png" alt="HasMedia" className='footer-logo'/></a>
                </p>
                <p className='footer-link'  data-aos="fade-up" data-aos-duration="200">
                  <a href="#whoweare">Who We Are</a>
                  <a href="#ourbusiness">What We Do</a>
                </p>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Footer