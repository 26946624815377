import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function GetInTouch() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={12}>
                <div>
                    <Form onSubmit={e=>e.preventDefault()} className='form-static'  data-aos="fade-up" data-aos-duration="700">
                        <Row>
                            <Col>
                                <h2>
                                    Get in touch with us
                                </h2>
                                <p>
                                Drive More Sales Through Your Business
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                            <Form.Control placeholder="Full Name" name="fullname" type="text"/>
                            </Col>
                            <Col md={6}>
                            <Form.Control placeholder="Email Address" name="email" type="email" />
                            </Col>
                            <Col md={6}>
                            <Form.Control placeholder="Phone Number" name="phone" type="number" />
                            </Col>
                            <Col md={6}>
                            <Form.Control placeholder="Address" name="address" type="text" />
                            </Col>
                            <Col md={12}>
                                <Form.Control
                                as="textarea"
                                placeholder="Message"
                                style={{ height: '100px' }}
                                />
                            </Col>
                            
                            <Col md={12}>
                            <Button variant="primary" type="submit" className='float-end mt-2 cs-btn-form'>
                                Submit
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default GetInTouch