import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function WhoWeAre() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={6}  data-aos="fade-right" data-aos-duration="700">
                <p>
                    <img src="./assets/img/chart-1.png" alt="Passionate Marketing Experts" />
                </p>
            </Col>
            <Col md={6}  data-aos="fade-left" data-aos-duration="700">
                <h2>Passionate Marketing Experts</h2>
                <p>Since 2013 we have helped businesses create their brand presence and achieve their goals. Our process is designed to empower your brand and outfit your business with the marketing tools needed to succeed. Talk to us today about how we can support your growth, limit your costs, and put you on a solid track to success and profit.</p>
                <p><strong>Our Analytical Approach</strong></p>
                <p>Our service includes a comprehensive consult to help identify gaps and opportunities, and results in a comprehensive report that includes a project plan with timelines a cost analysis. Our individualized plans are made up of quality services that will help you get there quickly and smoothly.</p>
            </Col>
        </Row>
    </Container>
  )
}

export default WhoWeAre