import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";

function Header({ activeSection }) {
  return (
    <>
        {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary" collapseOnSelect>
          <Container>
            <Navbar.Brand href="/"><img src="./assets/img/HasMedia-1.png" alt='HasMedia' /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src="./assets/img/HasMedia-1.png" alt='HasMedia' />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3  align-items-center">
                  <Nav.Link as={Link} eventKey="1" to="/#whoweare" className={activeSection === 1 ? "theme-color item--active" : "theme-color"}>Who we are</Nav.Link>
                  <Nav.Link as={Link} eventKey="3" to="/#ourbusiness" className={activeSection === 3 ? "theme-color item--active" : "theme-color"}>What we do</Nav.Link>
                </Nav>
                
                <Nav className='button-navbar get-started'>
                  <Nav.Link as={Link} eventKey="5" to="/#contact" className={activeSection === 3 ? "theme-color item--active" : "theme-color"}>Lets Talk</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default Header