import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HowWorks() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={6} data-aos="fade-right" data-aos-duration="700">
                <p>
                    <img src="./assets/img/20943868-1.jpg" alt="How HasMedia Works" />
                </p>
            </Col>
            <Col md={6}  data-aos="fade-left" data-aos-duration="700">
                <h2>How Has<span className='theme-color'>Media</span> Works</h2>
                <p>The HasMedia team is oriented and aligned with our clients…</p>
                <p>We get paid to deliver results. We focus our efforts to source new customers for your business through multiple digital channels that aren’t easily identifiable.</p>
                <p>We deliver results and service them like no other network in the world. Our high budgets, large payout and diversified offer portfolio make the HasMedia network the one-stop-shop to get the job done.</p>
            </Col>
        </Row>
    </Container>
  )
}

export default HowWorks