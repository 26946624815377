import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaTwitterSquare } from "@react-icons/all-files/fa/FaTwitterSquare";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare";

function Copyright() {
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={6} data-aos="fade-right" data-aos-duration="700">
                <p className='text-start text-white'>COPYRIGHT © 2023 HasMedia. All Rights Reserved.</p>
            </Col>
            <Col md={6} data-aos="fade-left" data-aos-duration="700">
                <p className='text-end text-white'>
                  <FaFacebookSquare/>
                  <FaTwitterSquare/>
                  <FaInstagramSquare/>
                </p>
            </Col>
        </Row>
    </Container>
  )
}

export default Copyright