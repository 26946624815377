import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function GlobalLeaders() {
    const scrollToElement = (id) => {
      const container = document.getElementById(id.substring(1));
      container.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
  return (
    <Container>
        <Row className='align-items-center'>
            <Col md={6}>
                <h2 data-aos="fade-right" data-aos-duration="700" >We are global leaders in contextual targeting and creativity</h2>
                
                <div className='text-start'  data-aos="fade-up" data-aos-duration="1000">
                <Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                </div>
            </Col>
            <Col md={6}>
            </Col>
        </Row>
    </Container>
  )
}

export default GlobalLeaders