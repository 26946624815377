import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


function Hero() {
    
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <Container  className='heroSection'>
        <Row className="justify-content-md-center align-items-center">
            <Col md={12} className='hero-left-col'>
                <h1 className='text-lg-start' data-aos="zoom-in">HAS <span className='theme-color'>MEDIA</span></h1>
                <div className="text-start text-white cs-shadow typetext"  data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400" data-aos-duration="1000">
                  <p className='text-white'>Built to deliver your objectives across the Globe</p>
                </div>
                <div className='text-center'  data-aos="fade-up" data-aos-duration="1500">
                <Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us</Button>
                </div>
            </Col>
        </Row>
    </Container >
    
  )
}

export default Hero