import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function OurBusiness() {
  return (
    <Container>
        <Row>
            <Col>
                <h2 data-aos="fade-up" data-aos-duration="500">Our business is <span className='theme-color'>making your business better.</span></h2>
            </Col>
        </Row>
        <Row>
            <Col md={6} lg={3} className='mb-4'  data-aos="fade-up" data-aos-duration="600">

                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <img src="./assets/img/undraw_segmentation_uioo.png" alt="ADVERTISING" />
                    </div>
                        <Card.Title className='theme-color-2'>ADVERTISING</Card.Title>
                        <Card.Text>
                        Advertise overdifferent platform for outstanding click- through response and traffic
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} lg={3} className='mb-4'  data-aos="fade-down" data-aos-duration="600">

                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <img src="./assets/img/undraw_charts_jj6t.png" alt="LEAD GENERATION" />
                    </div>
                        <Card.Title className='theme-color-2'>LEAD GENERATION</Card.Title>
                        <Card.Text>
                        Get targeted leads from continuously updated 270 million consumer and 14 million business databases
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} lg={3} className='mb-4'  data-aos="fade-up" data-aos-duration="600">

                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <img src="./assets/img/undraw_social_growth_d0y3.png" alt="PUBLISHING" />
                    </div>
                        <Card.Title className='theme-color-2'>PUBLISHING</Card.Title>
                        <Card.Text>
                        Find quality offers with excellent payouts in profitable verticals on our affiliate network
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6} lg={3} className='mb-4'  data-aos="fade-down" data-aos-duration="600">

                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <img src="./assets/img/undraw_segmentation_uioo.png" alt="CREATIVE SERVICES" />
                    </div>
                        <Card.Title className='theme-color-2'>CREATIVE SERVICES</Card.Title>
                        <Card.Text>
                        Measurably increase response with dynamic design based on data analytics by our inhouse firm
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default OurBusiness